import { createSlice } from '@reduxjs/toolkit';

const contractsSlice = createSlice({
  name: 'contracts',
  initialState: [],
  reducers: {
    setContracts: (state, action) => {
      // window.contracts = action.payload;
      return action.payload;
    },
  },
});

export const contractsReducer = contractsSlice.reducer;

// Action creators are generated for each case reducer function
export const { setContracts } = contractsSlice.actions;